import React, { Component } from 'react'
import { createGlobalStyle } from 'styled-components'
import { isMobile } from 'react-device-detect'
import { io } from 'socket.io-client'
// import gsap from 'gsap'

import Main from './Main'
import Posts from './Posts'

const GlobalStyle = createGlobalStyle`
  body {
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "Helvetica";
    font-size: 1.05rem;
    letter-spacing: 0.15px;
    line-height: 1.3rem;
    display: grid;
    justify-items: center;

    a {
      text-decoration: none;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1.5px solid;
      }
    }

    #root {
      display: grid;
      max-width: 768px;
      min-width: 768px;
      grid-template-columns: 100%;
      grid-template-areas:
        "main"
        "posts";

      @media screen and (max-width: 768px) {
        min-width: 100vw;
        font-size: 0.975rem;
        letter-spacing: 0.15px;
        grid-template-columns: 100%;
        grid-template-areas:
          "main"
          "posts";
      }
    }
  }

  .user {
    position: absolute;
    width: 20px;
    height: 30px;
    background-repeat: no-repeat;
    background-image: url('assets/mac-cursor.png');
    background-size: 18px;
    pointer-events: none;
  }
`

class App extends Component {
  state = {
    users: new Map(),
  }

  componentDidMount() {
    if (isMobile) return

    const socket = io.connect(
      process.env.NODE_ENV === 'development'
        ? '//localhost:5000'
        : `//api.${window.location.hostname}`,
    )

    window.addEventListener('mousemove', ({ clientX: x, clientY: y }) => {
      socket.emit('mousePosition', { x, y })
    })

    socket.on('mousePosition', ({ id, ...data }) => {
      this.setState((state) => {
        const users = new Map(state.users)
        users.set(id, data)
        return { ...state, users }
      })
    })

    socket.on('userDisconnected', (data) => {
      this.setState((state) => {
        const users = new Map(state.users)
        users.delete(data.id)
        return { ...state, users }
      })
    })
  }

  render() {
    return (
      <>
        <GlobalStyle />
        <Main />
        <Posts />
        {[...this.state.users].map(([id, user], i) => (
          <div
            key={`${id}-${i}`}
            className="user"
            style={{
              top: user.y - 8,
              left: user.x - 5,
            }}
          />
        ))}
      </>
    )
  }
}

export default App
