import React, { Component } from 'react'
import styled from 'styled-components'

const S = {
  Container: styled.div`
    grid-area: main;
    padding: 20px;
  `,
}

class Main extends Component {
  render() {
    const contact = ({ user, domain = window.location.hostname }) => {
      window.location.href = atob('bWFpbHRvOiV1c2VyJUBnJWRvbWFpbiUuY29t')
        .replace('%user%', user)
        .replace('%domain%', domain)
        .concat('?Subject=Hello')
      return false
    }

    return (
      <S.Container className="Main">
        Tjerk Woudsma
        <br />
        Creative Developer, Designer
        <br />
        <br />
        <a
          href="https://www.linkedin.com/in/tjerk-woudsma-662432157"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <br />
        <a
          href="https://github.com/woudsma"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github
        </a>
        <br />
        <a href="https://blog.tjerkwoudsma.com" rel="noopener noreferrer">
          Personal blog
        </a>
        <br />
        <br />
        Currently working as a JavaScript Consultant at&nbsp;
        <a
          href="https://passionatepeople.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Passionate People
        </a>
        <br />
        Contact:&nbsp;
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            contact({ domain: 'mail', user: 'tjerkwoudsma' })
          }}
          target="_top"
        >
          mail
        </a>
        <br />
        <br />
        <br />
        WIP weekend projects:
        <br />
        <a
          href="https://swarmlet.dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          Swarmlet
        </a>
        , a self-hosted, open-source PaaS
        <br />
        <a
          href="https://github.com/woudsma/pi-sequencer"
          target="_blank"
          rel="noopener noreferrer"
          disabled
        >
          Heron
        </a>
        , a hardware MIDI sequencer
        <br />
        ...
        <br />
        <br />
      </S.Container>
    )
  }
}

export default Main
